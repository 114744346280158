import React from 'react';
import Newsletter from './Components/Newsletter';

function Home() {

  return (
    <>
      <div className="offcanvas-overlay"></div>
      <div className="hero-display-section section-fluid-200">
        <div className="box-wrappr">
          <div className="hero-wrapper">
            <div className="hero-content">
              <span className="title-tag">Metasol </span>
              <div className="section-content section-content-gap-50 ">
                      <h3 className="section-title">We are creative digital, enthusiastic</h3>
                    </div>
              <h4 className="title"></h4>
              
             
              <p>D-U-N-S Registered Seal for your company is ready to be used for your website and email signatures. You may need assistance from the person / team who manages your website to place the seal on your website / email signature. Installation guide(s) are attached for your reference.</p>
            
            </div>
            <div className="hero-image">
              <img className="img-fluid" src="assets/images/hero/hero.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className="count-display section-fluid-200 section-inner-padding-100 section-inner-gray-gradient-bg-reverse">
        <div className="box-wrapper">
          <div className="count-wrapper pos-relative">
            <div className="container-fluid">
              <div className="row align-items-center justify-contents-between">
                <div className="col-xl-5 offset-xl-0 col-md-10 offset-md-1 col-sm-12">
                  <div className="content text-lg-start text-center">
                    <h3 className="title">We’r <span>10,000</span> project
                      complete & <span>100%</span> client
                      satisfaction.</h3>
                  </div>
                </div>
                <div className="col">
                 
                </div>
              </div>
            </div>
            <div className="dotline-animate">
              <span className="blue"></span>
              <span className="orange"></span>
              <span className="blue"></span>
            </div>
          </div>
        </div>
      </div>

    
      <Newsletter />
    </>
  )
}

export default Home;