
export const ServiceDropdown = [
    {
        id: "1",
        Sname: "Custom Website Design",
        path: "/custom-website-design",
        title: "Custom Website Design",
        subtitle: "DISCOVER THE SERVICES WE PROVIDE",
        mainhding: "Custom Website Design We are Digital Business Agency",
        Spara: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert",
        Spara2: "Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert unit and clutch your site visitors to be the patrons.",
        pagepara: "If your continuous marketing efforts are merely generating visitors for your business, you need our experts’ services to make them customers.",
        firstli: "Get exceptional service at fair payment.",
        secondli: "Obtain website design that perfectly suits your business.",
        thirdli: "We willingly consider your satisfaction our priority.",
        Serviceimg: "/assets/images/service/img-1.jpg",
        img: '/assets/images/icons/promo/Custom-Website-Design.png',
        img2: '/assets/images/icons/promo/Custom-Website-Design-2.png',
        heading: "Custom Website Design",
        description: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert.",
        serlink: "/servicedetail/1"
    },
    {
        id: "2",
        Sname: "Content Marketing",
        path: "/content-marketing",
        title: "Content Marketing",
        subtitle: "DISCOVER THE SERVICES WE PROVIDE",
        mainhding: "Content Marketing We are Digital Business Agency",
        Spara: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert",
        Spara2: "Our spectacular amenities ensure the success of your business. Use our classy content marketing services as the prop for your business.",
        pagepara: "If your continuous marketing efforts are merely generating visitors for your business, you need our experts’ services to make them customers.",
        firstli: "  Get fresh regal content at better plans.",
        secondli: " Receive phenomenal content marketing.",
        thirdli: "   Acquire search engine friendly material.",
        Serviceimg: "/assets/images/service/img-2.jpg",
        img: '/assets/images/icons/promo/Content-Marketing.png',
        img2: '/assets/images/icons/promo/Content-Marketing-2.png',
        heading: "Content Marketing",
        description: "We are Digital Business Agency Our spectacular amenities ensure the success of your business. Use our classy content marketing services as",
        serlink: "/servicedetail/2"
    },
    {
        id: "3",
        Sname: "Pay Per Click",
        path: "/pay-per-click",
        title: "Pay Per Click",
        subtitle: "DISCOVER THE SERVICES WE PROVIDE",
        mainhding: "Pay Per Click We are Digital Business Agency",
        Spara: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert",
        Spara2: "Our effective cooperation won’t let your business down. Reach out to your targeted audience more precisely utilizing our offered pay-per-click marketing services.",
        pagepara: "If your continuous marketing efforts are merely generating visitors for your business, you need our experts’ services to make them customers.",
        firstli: "  Attain thorough PPC management and advertisement.",
        secondli: "  Receive striking assistance at minor cost.",
        thirdli: "   Secure premium return on investment (ROI).",
        Serviceimg: "/assets/images/service/img-3.jpg",
        img: '/assets/images/icons/promo/Pay-Per-Click-2.png',
        img2: '/assets/images/icons/promo/Pay-Per-Click.png',
        heading: "Pay Per Click",
        description: "We are Digital Business Agency Our effective cooperation won’t let your business down. Reach out to your targeted audience more precisely.",
        serlink: "/servicedetail/3"
    },
    {
        id: "4",
        Sname: "Social Media Marketing",
        path: "/social-media-marketing",
        title: "Social Media Marketing",
        subtitle: "DISCOVER THE SERVICES WE PROVIDE",
        mainhding: "Social Media Marketing We are Digital Business Agency",
        Spara: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert",
        Spara2: "Our supreme services confirm stabilized victory of your business. Get foolproof social media marketing services with guaranteed ceaseless height of your business.",
        pagepara: "If your continuous marketing efforts are merely generating visitors for your business, you need our experts’ services to make them customers.",
        firstli: "  Acquire strategic weekly plans to grasp more audience.",
        secondli: "  Get timely modifications on the basis of latest trends.",
        thirdli: "   We utilize every single channel to boost your business.",
        Serviceimg: "/assets/images/service/img-4.jpg",
        img: '/assets/images/icons/promo/Social-Media-Marketing-2.png',
        img2: '/assets/images/icons/promo/Social-Media-Marketing.png',
        heading: "Social Media Marketing",
        description: "We are Digital Business Agency Our supreme services confirm stabilized victory of your business. Get foolproof social media marketing services with",
        serlink: "/servicedetail/4"
    },
    {
        id: "5",
        Sname: "Search Engine Marketing",
        path: "/search-engine-marketing",
        title: "Search Engine Marketing",
        subtitle: "DISCOVER THE SERVICES WE PROVIDE",
        mainhding: "Search Engine Marketing We are Digital Business Agency",
        Spara: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert",
        Spara2: "Our unusual features promise the prosperity of your business. Attain flawless relevant marketing services from our skillful Search Engine Marketers to guarantee your first-page visibility.",
        pagepara: "If your continuous marketing efforts are merely generating visitors for your business, you need our experts’ services to make them customers.",
        firstli: "  Get Itemized optimal SEM strategy.",
        secondli: "  Obtain effectual plans at cooler rates.",
        thirdli: "   Achieve continuous attention of our experts.",
        Serviceimg: "/assets/images/service/img-5.jpg",
        img: '/assets/images/icons/promo/Search-Engine-Marketing.png',
        img2: '/assets/images/icons/promo/Search-Engine-Marketing-2.png',
        heading: "Search Engine Marketing",
        description: "We are Digital Business Agency Our unusual features promise the prosperity of your business. Attain flawless relevant marketing services from",
        serlink: "/servicedetail/5"
    },
    {
        id: "6",
        Sname: "Search Engine Optimization",
        path: "/search-engine-optimization",
        title: "Search Engine Optimization",
        subtitle: "DISCOVER THE SERVICES WE PROVIDE",
        mainhding: "Search Engine Optimization We are Digital Business Agency",
        Spara: "We are Digital Business Agency Our lofty offers confirm your long-lasting triumph. Get customized website designs formulated by our expert",
        Spara2: "Our exceptional team services enable your business to positively fly higher in skies. Acquire our Search Engine Optimization services to lead search engines and to multiply your audience organically.",
        pagepara: "If your continuous marketing efforts are merely generating visitors for your business, you need our experts’ services to make them customers.",
        firstli: "  Get SEO optimized content.",
        secondli: "  Ensure your first page ranking.",
        thirdli: "   Grow your business circle.",
        Serviceimg: "/assets/images/service/img-6.jpg",
        id: "6",
        img: '/assets/images/icons/promo/Search-Engine-Optimization.png',
        img2: '/assets/images/icons/promo/Search-Engine-Optimization-2.png',
        heading: "Search Engine Optimization",
        description: "We are Digital Business Agency Our exceptional team services enable your business to positively fly higher in skies. Acquire our Search",
        serlink: "/servicedetail/6"
    },



]