import React from 'react';
import Banner from './Components/Banner';

function Contact() {

   

    return (
        <>

            <Banner home={"Home"} title={"Contact us"} liItem={"Contact us"} />

            <div className="form-section section-inner-padding-150">
                <div className="box-wrapper">
                    <div className="section-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-8 offset-xl-2">
                                    <div className="section-content section-content-gap-80 text-center">
                                      
                                        <h3 className="section-title">Contact Us</h3>
                                        <h3>If you have any questions regarding your privacy while playing our games, or have questions about our practices, please contact us via email at:
                                        </h3>
                                        <h3>support@metasoltechnologies.com
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className="map-section">
                <div className="box-wrapper">
                    <div className="map-wrapper">

                        

                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;