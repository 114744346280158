import React from 'react';
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <main className='mail-wrapper'>
        <footer className="footer-section section-top-gap-0">
          <div className="box-wrapper">

            <div className="footer-top footer-top-style-1">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="footer-top-box">
                      <div className="footer-top-left">

                        <div className="footer-single-widget footer-about">
                          <Link to="/" className="footer-logo">
                          <iframe id='Iframe1' src='https://dunsregistered.dnb.com/SealAuthentication.aspx?Cid=1' width='114px' height='97px' frameborder='0' scrolling='no' allowtransparency='true' ></iframe>  
    
                          </Link>
                         
                        </div>

                      </div>
                      <div className="footer-top-right">

                        <div className="footer-single-widget footer-menu">
                          <h5 className="footer-title">Company</h5>
                          <ul className="footer-nav">
                            <li><Link to="/about">About Us</Link></li>
                            <li><Link to="/contact">Contact Us</Link></li>

                            <li><Link to="#">Careers</Link></li>
                          </ul>
                        </div>

                        <div className="footer-single-widget footer-menu">
                          <h5 className="footer-title">Quick Links</h5>
                          <ul className="footer-nav">
                            <li><Link to="privacypolicy">Privacy Policy</Link></li>
                            <li><Link to="#">Terms & Conditions</Link></li>
                          </ul>
                        </div>

                       

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer-bottom">
              <div className="container">
                <div className="row justify-content-center justify-content-md-between align-items-center">
                  <div className="col-12">
                    <p className="copytight-text">&copy; 2022 Metasol. Made with <i className="icofont-heart"></i> by <Link to="/" target="_blank" rel="noopener noreferrer">Metasol</Link></p>
                  </div>

                
                </div>
              </div>
            </div>

          </div>
        </footer>
      </main>
    </>
  )
}

export default Footer;