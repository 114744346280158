import React from 'react';
import Counter from './Components/Counter';
import Servicebox from './Components/Servicebox';
import Singleblog from './Components/Singleblog';
import Newsletter from './Components/Newsletter';

function PrivacyPolicy() {

  return (
    <>
      <div className="offcanvas-overlay"></div>
      <div className="hero-display-section section-fluid-200">
        <div className="box-wrappr">
        <div className="box-wrapper" style={{marginTop:"100px"}} ></div>
        <h3 className="subtitle">Terms and Conditions for Metasol Technologies</h3>
        <h2 className="smalltitle">Introduction</h2>
        <p>These Website Standard Terms and Conditions written on this webpage shall manage your use of our website, Metasol Technologies accessible at https://metasoltechnologies.com/.</p>

<p>These Terms will be applied fully and affect your use of this Website. By using this Website, you agreed to accept all terms and conditions written here. You must not use this Website if you disagree with any of these Website Standard Terms and Conditions. </p>
<p>Minors or people below 16 years old are not allowed to use this Website.</p>

<h2 className="subtitle"> Intellectual Property Rights</h2>

<p>Other than the content you own, under these Terms, Metasol Technologies and/or its licensors own all the intellectual property rights and materials contained in this Website.</p>

<p>You are granted a limited license only for purposes of viewing the material contained on this Website.</p>

<h2 className="subtitle">Restrictions</h2>

<p>You are specifically restricted from all of the following:</p>

<ol>
    <li>  publishing any Website material in any other media;</li>
    <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
    <li>publicly performing and/or showing any Website material;</li>
    <li>using this Website in any way that is or may be damaging to this Website;</li>
    <li>using this Website in any way that impacts user access to this Website;</li>
    <li>using this Website contrary to applicable laws and regulations, or in any way may cause harm to the Website, or to any person or business entity;</li>
    <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website;</li>
    <li>using this Website to engage in any advertising or marketing.</li>
</ol>

<p>Certain areas of this Website are restricted from being access by you and Metasol Technologies may further restrict 
  access by you to any areas of this Website, at any time, in absolute discretion. Any user ID and password you may have for this Website are 
  confidential and you must maintain confidentiality as well.</p>

<h2 className="subtitle">Your Content</h2>

<p>In these Website Standard Terms and Conditions, "Your Content" shall mean any audio, video text, images or 
  other material you choose to display on this Website. By displaying Your Content, you grant Metasol Technologies a non-exclusive, 
  worldwide irrevocable, sub licensable license to use, reproduce, adapt, publish, translate and distribute it in any and all media.</p>

<p>Your Content must be your own and must not be invading any third-party’s rights.
   Metasol Technologies reserves the right to remove any of Your Content from this Website at any time without notice.</p>

<h2>Your Privacy</h2>

<p>The Metasol Team cares about user privacy. Please read our  Privacy Policy carefully. If you don't agree with this, 
  please do not use our apps and games.our privacy policy is available at the link below 
 https://metasolgames.blogspot.com.</p>
<h2>No warranties</h2>

<p>This Website is provided "as is," with all faults, and Metasol Technologies express no representations or warranties,
   of any kind related to this Website or the materials contained on this Website. Also, nothing contained on this Website shall be interpreted as advising you.</p>

<h2>Limitation of liability</h2>

<p>In no event shall Metasol Technologies, nor any of its officers, directors and employees,
   shall be held liable for anything arising out of or in any way connected with your use of this Website whether such liability is under contract.  Metasol Technologies, 
   including its officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any 
   way related to your use of this Website.</p>

<h2>Indemnification</h2>

<p>You hereby indemnify to the fullest extent Metasol Technologies from and against any and/or all liabilities, 
  costs, demands, causes of action, damages and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>

<h2>Severability</h2>

<p>If any provision of these Terms is found to be invalid under any applicable law,
   such provisions shall be deleted without affecting the remaining provisions herein.</p>

<h2>Variation of Terms</h2>

<p>Metasol Technologies is permitted to revise these Terms at any time as it sees fit, 
  and by using this Website you are expected to review these Terms on a regular basis.</p>

<h2>Assignment</h2>

<p>The Metasol Technologies is allowed to assign, transfer, and subcontract its rights and/or obligations 
  under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>

<h2>Entire Agreement</h2>
    
<p>These Terms constitute the entire agreement between Metasol Technologies and you in relation 
  to your use of this Website, and supersede all prior agreements and understandings.</p>

<h2>Governing Law & Jurisdiction</h2>

<p>These Terms will be governed by and interpreted in accordance with the laws of the State of pk, and you submit to the non-exclusive jur </p>

        </div>
      </div>

    

     

      <Newsletter />
    </>
  )
}

export default PrivacyPolicy;