import React from 'react';
import Newsletter from './Components/Newsletter';
import Banner from './Components/Banner';

function About() {

  return (
    <div style={{marginBottom:'100px'}} >
    <Banner home={"Home"} title={"About"} liItem={"About"}/>
            <div className="container">
            <h3 className="section-title" style={{marginTop:'100px', }} >What are my opt-out rights?</h3>
            <p>You can stop all collection of information by the Application easily by uninstalling the Application. You may use the standard uninstall processes as may be available as part of your mobile device or via the mobile application marketplace or network. You can also request to opt-out via email, at: FIRE SNIPER COMBAT: FPS 3D SHOOTING GAME.</p>
            <ul className="default-iconic-item-2">
                      <li>
                        <div className="icon"><i className="icofont-check"></i></div>
                        <div className="content">
                          <h4 className="smalltitle">Data Retention Policy, Managing Your Information</h4>
                          <p>We will retain User Provided data for as long as you use the Application and for a reasonable time thereafter. If you would like us to delete User Provided Data that you have provided via the Application, please contact us at FIRE SNIPER COMBAT: FPS 3D SHOOTING GAME and we will respond in a reasonable time. Please note that some or all of the User Provided Data may be required in order for the Application to function properly.</p>
                        </div>
                      </li>
                      <li>
                        <div className="icon"><i className="icofont-check"></i></div>
                        <div className="content">
                          <h4 className="smalltitle">Children</h4>
                          <p>This Games has some strong violence therefore children under the age 16 are not allowed to play this game.However few of our games are available for children under agre 13 under the guidance of their legal guardian. The guidance regarding our privacy policies that concern collecting, using and disclosing personal information of children under 13.</p>
                        </div>
                      </li>
                   </ul>                  
            </div>
    </div>
  )
}

export default About;